import React from 'react'
import { Link } from 'gatsby'
import noop from 'lodash/noop'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames'
import Img from 'gatsby-image'
import Layout from './layout'
import { Hamburger } from '.'
import Seo from './seo'
import Animate from './animate'
import {
  Main,
  MainHover,
  Google,
  GoogleHover,
  Fb,
  FbHover,
  Android,
  AndroidHover,
  Instagram,
  InstagramHover,
  Design,
  Cloud,
  CloudHover,
  DesignHover,
  MxFlag,
  CanadaFlag,
  EuFlag,
  BlueLeftArrow,
  BlueRightArrow,
} from '../images/projects'
import ErickVideo from '../videos/projects/erickReguera.mp4'
import ISeatzVideo from '../videos/projects/iseatz.mp4'
import BurgersVideo from '../videos/projects/coopsBurgers.mp4'
import BlissLabel from './blissLabel'

import '../styles/project_main.scss'

const getImage = (images, prefix, name) => images.find(image => image.originalName === `${prefix}-${name}.png`)

const Project = ({ data, windowWidth, lang, location }) => {
  const mobile = windowWidth < 640
  const { sitePage, allImageSharp, logo } = data
  const info = sitePage.context.projectData
  const pageId = sitePage.id
  const page = data.allSitePage.edges.find(edge => edge.node.id === pageId)
  const projectsPath = `/${lang.lang}/projects/`

  const postData = {
    title: `Bliss DDM | ${lang.portfolio} | ${info.name.fullName}`,
    slug: info.path,
    date: info.year,
  }
  const images = data.allImageSharp.nodes.map(node => node.sizes)
  const phoneImage = images.find(image => image.originalName === 'phone.png')

  const components = {
    main: (
      <a href={info.website} target="_blank">
        <Main className="Project-socialIcon" />
      </a>
    ),
    mainHover: (
      <a href={info.website} target="_blank">
        <MainHover
          className="Project-socialIcon Project-socialIcon--hover"
          data-tip={lang.website1}
        />
      </a>
    ),
    android: <Android className="Project-socialIcon" />,
    androidHover: (
      <AndroidHover
        className="Project-socialIcon Project-socialIcon--hover"
        data-tip={lang.mobileApp}
      />
    ),
    fb: <Fb className="Project-socialIcon" />,
    fbHover: (
      <FbHover
        className="Project-socialIcon Project-socialIcon--hover"
        data-tip="Facebook"
      />
    ),
    google: <Google className="Project-socialIcon" />,
    googleHover: (
      <GoogleHover
        className="Project-socialIcon Project-socialIcon--hover"
        data-tip="Google"
      />
    ),
    instagram: <Instagram className="Project-socialIcon" />,
    instagramHover: (
      <InstagramHover
        className="Project-socialIcon Project-socialIcon--hover"
        data-tip="Instagram"
      />
    ),
    design: <Design className="Project-socialIcon" />,
    cloud: <Cloud className="Project-socialIcon" />,
    designHover: (
      <DesignHover
        className="Project-socialIcon Project-socialIcon--hover"
        data-tip={lang.design}
      />
    ),
    cloudHover: (
      <CloudHover
        className="Project-socialIcon Project-socialIcon--hover"
        data-tip={lang.cloud}
      />
    ),
    erickVideo: ErickVideo,
    burgersVideo: BurgersVideo,
    iseatzVideo: ISeatzVideo,
    mxFlag: <MxFlag className="Project-flag" />,
    canadaFlag: <CanadaFlag className="Project-flag" />,
    euFlag: <EuFlag className="Project-flag" />,
  }

  return (
    <Layout
      lang={lang}
      location={location}
      isProjects
      contrast
      logo={logo.sizes}
      images={allImageSharp.nodes.map(node => node.sizes)}
      hideHeaderOnMobile
    >
      <div className="Project">
        {mobile && (
          <Link to={`/${lang.lang}/portfolio`}>
            <Hamburger isActive className="Project-hamburger" toggle={noop} />
          </Link>
        )}
        <div className="Project-container">
          <div className="Project-content">
            <Animate className="Project-info">
              {animateClassNames => (
                <div className={animateClassNames}>
                  <BlissLabel label="Estudio Digital" />
                  <div className={classNames('Project-title', { 'Project-title--noLeftArrow': !page.previous })}>
                    {page.previous && (
                      <Link
                        className="Project-arrowLinkMobile"
                        to={page.previous.path}
                      >
                        <BlueLeftArrow className="Project-leftArrow" />
                      </Link>
                    )}
                    {info.name.white}
                    <span className="Project-title Project-title--blue">
                      {info.name.blue}
                    </span>
                    {page.next && (
                      <Link
                        className="
                          Project-arrowLinkMobile
                          Project-arrowLinkMobile--right
                        "
                        to={page.next.path}
                      >
                        <BlueRightArrow className="Project-rightArrow" />
                      </Link>
                    )}
                  </div>
                  <div className="Project-flagYear">
                    <div className="Project-year">{info.year}</div>
                    <div className="Project-flagContainer">{components[info.flag]}</div>
                  </div>
                  <div className="Project-description">{info.description}</div>
                </div>
              )}
            </Animate>
            <div className="Project-infoTwo">
              <div className="Project-btnContainer">
                <Link to={projectsPath}>
                  <Animate className="Project-btn">
                    {animateClassNames => (
                      <button
                        type="button"
                        className={animateClassNames}
                      >
                        {lang.moreProjects}
                      </button>
                    )}
                  </Animate>
                </Link>
              </div>
              <Animate className="Project-technologiesContainer">
                {ProjecClassNames => (
                  <div className={ProjecClassNames}>
                    <div className="Project-technologies">
                      {info.technologies.map((technology, idx) => (
                        <div
                          className={classNames('Project-technology', { 'Project-technology--first': idx === 0 })}
                          key={technology}
                        >
                          {components[technology]}
                        </div>
                      ))}
                    </div>
                    <div className="Project-technologies Project-technologies--hover">
                      {info.technologies.map(technology => (
                        <div
                          className="Project-technology Project-technology--hover"
                          key={technology}
                        >
                          {components[`${technology}Hover`]}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Animate>
            </div>
          </div>
          <div
            className={classNames('Project-fileContainer', { 'Project-fileContainer--video': info.video })}
          >
            {page.previous && (
              <Link
                className="Project-arrowLink Project-arrowLink--left"
                to={page.previous.path}
              >
                <BlueLeftArrow className="Project-leftArrow" />
              </Link>
            )}
            {info.image
              && (!info.video || mobile) && (
                <div>
                  {getImage(images, 'project', info.image) && (
                    <Img
                      className="Project-image Project-image--hideOnMobile"
                      alt={info.image}
                      sizes={getImage(images, 'project', info.image)}
                    />
                  )}
                  {getImage(images, 'smallProject', info.image) && (
                    <Img
                      className="Project-image Project-image--hideOnDesktop"
                      alt={info.image}
                      sizes={getImage(images, 'smallProject', info.image)}
                    />
                  )}
                </div>
            )}
            {info.video && !mobile && info.video !== 'burgersVideo' && (
              <video className="Project-video" autoPlay loop muted>
                <source src={components[info.video]} type="video/mp4" />
              </video>
            )}
            {info.video === 'burgersVideo' && !mobile && (
              <div className="Project-videoContainer">
                <video className="Project-video Project-video--coops" autoPlay loop muted>
                  <source src={components[info.video]} type="video/mp4" />
                </video>
                <Img
                  className="Project-phone"
                  alt="phone image"
                  sizes={phoneImage}
                />
                <video className="Project-video Project-video--coops Project-video--visible" autoPlay loop muted>
                  <source src={components[info.video]} type="video/mp4" />
                </video>
              </div>
            )}
            {page.next && (
              <Link
                className="Project-arrowLink Project-arrowLink--right"
                to={page.next.path}
              >
                <BlueRightArrow className="Project-rightArrow" />
              </Link>
            )}
          </div>
        </div>
        <ReactTooltip className="Project-tooltip" effect="solid" />
      </div>
      <Seo
        lang={lang}
        postData={postData}
        imageOverride={`/seo/portfolio/${info.image}.jpg`}
        isBlogPost
        descriptionOverride={lang.portfolioSeo}
      />
    </Layout>
  )
}

export default Project
